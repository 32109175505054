import { AddressVerificationInputCodes } from "@/api/melissa-address/enums/address-verification-enums"
import { Parameter } from "@/api/melissa-address/types/melissa-address-types"
import { EMAIL_REGEX, ADDRESS_CHARACTER_LIMIT_REGEX, getPostalCodeRegex } from "@/api/validation"
import { AddressFormFieldsWarning } from "@/components/shared/address/types/address-types"
import { AddressBookFormWarning, useAddressStore } from "@/stores/useAddressStore"
import { useCartStore } from "@/stores/useCartStore"
import classNames from "classnames"
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { phoneNumberMask } from '../masks/phone-number-mask'
import { CheckoutStepOneBillingFormInputs, CheckoutStepOneBillingFormProps } from "../types/checkout-types"
import { useTranslation } from "react-i18next"
import { renderErrors, renderWarnings } from "@/api/validation/input-validation"

const StepOneBillingForm: React.FC<Readonly<CheckoutStepOneBillingFormProps>> = (props: Readonly<CheckoutStepOneBillingFormProps>) => {
    const {t} = useTranslation()
    const { cartData, updateCartShippingMethod } = useCartStore()
    const { hasWarning, setHasWarning, getValidatedAddress } = useAddressStore()
    const [ warnings, setWarnings ] = useState<{ [key in keyof AddressFormFieldsWarning]?: string[] } | null>(null)

    const existingBillingAddressCountryCode = cartData?.fullCart?.payments[0]?.billingAddress?.countryCode ?? ""

    const { className, defaultCartBillingAddress: billingAddress, localizations, onSubmitForm } = props
    
    const {
        register,
        handleSubmit,
        control,
        setFocus,
        setValue,
        watch,
        trigger,
        formState: { 
        errors, 
        },
    } = useForm<CheckoutStepOneBillingFormInputs>({
        values:  {
            address1: billingAddress?.line1 ?? "",
            address2: billingAddress?.line2 ?? "",
            city: billingAddress?.city ?? "",
            country: billingAddress?.countryName ?? "",
            countryCode: billingAddress?.countryCode ?? "",
            postalCode: billingAddress?.postalCode ?? "",
            provinceOrState: billingAddress?.regionName ?? "",
            email: billingAddress?.email ?? "",
            phone: billingAddress?.phoneNumber ?? "",
            addressName: billingAddress?.name ?? "",
        },
    })

    const defaultFormStyles = classNames("gap-3", className)

    const [address1Warning, setAddress1Warning] = useState<boolean>(false);
    useEffect(() => {
        const address1Value = watch('address1');
        if (address1Value && address1Value.length > 64) {
            setAddress1Warning(true);
        } else {
            setAddress1Warning(false);
        }
    }, [watch('address1')]);
    const [address2Warning, setAddress2Warning] = useState<boolean>(false);
    const address2Value = watch('address2');
    useEffect(() => {
        if (address2Value && address2Value.length > 64) {
            setAddress2Warning(true);
        } else {
            setAddress2Warning(false);
        }
    }, [address2Value]);


    useEffect(() => {
        if (billingAddress !== null) 
        {
            setValue("addressName", billingAddress.name ?? "", {shouldDirty: true})
            setValue("address1", billingAddress.line1 ?? "", {shouldDirty: true})
            setValue("address2", billingAddress.line2 ?? "", {shouldDirty: true})
            setValue("city", billingAddress.city ?? "", {shouldDirty: true})
            setValue("country", billingAddress.countryName ?? "", {shouldDirty: true})
            setValue("countryCode", billingAddress.countryCode ?? "", {shouldDirty: true})
            setValue("provinceOrState", billingAddress.regionName ?? "", {shouldDirty: true})
            setValue("postalCode", billingAddress.postalCode ?? "", {shouldDirty: true})
            setValue("email", billingAddress.email ?? "", { shouldDirty: true })
            setValue("phone", billingAddress.phoneNumber ?? "", { shouldDirty: true })
        }
    }, [billingAddress])

    const watchedFields = watch();

    // Update billing country shipping method on country/trigger change
    useEffect(() => {
        if (watchedFields.countryCode && watchedFields.countryCode !== existingBillingAddressCountryCode) {
            updateCartShippingMethod({shipmentId: cartData?.fullCart?.shipments[0]?.id ?? null, shippingMethod: null})
        }
    }, [watchedFields.countryCode, trigger]);

    const onSubmit: SubmitHandler<CheckoutStepOneBillingFormInputs> = async (data, e) => {

        const hasWarnings = await handleAddressValidation(data)
        if (hasWarnings)
        {
            if (hasWarning === false)
            {
                return
            }
        }
        setHasWarning(false)
        onSubmitForm(data, e)
    }

    const handleAddressValidation = async (data: CheckoutStepOneBillingFormInputs) => {
        const addressVerificationParams: Parameter[] =
        [
            {
              paramType: AddressVerificationInputCodes.Address1,
              paramValue: data.address1,
            },
            {
              paramType: AddressVerificationInputCodes.Country,
              paramValue: data.countryCode,
            },
            {
              paramType: AddressVerificationInputCodes.City,
              paramValue: data.city,
            },
            {
              paramType: AddressVerificationInputCodes.PostalCode,
              paramValue: data.postalCode,
            },
            {
              paramType: AddressVerificationInputCodes.StateOrProvince,
              paramValue: data.provinceOrState,
            }
        ]
  
        const addressVerificationResult = await getValidatedAddress(addressVerificationParams)
        if(addressVerificationResult)
        {
            const isWarnings = addressVerificationResult?.verificationWarnings && addressVerificationResult?.verificationWarnings.length > 0
            isWarnings && setHasWarning(true)
            isWarnings && addressVerificationResult?.verificationWarnings.forEach((warning) => {
                setWarnings(prevWarnings => {
                    const key = warning.warningId as keyof AddressBookFormWarning;
                    return {
                        ...prevWarnings,
                        [key]: warning?.warningMessages
                    };
                });
            });
            return isWarnings
        }
        return false;
    }
    
    // Focus handler for when we have warnings.
    useEffect(() => {
      if (!hasWarning) return;

      if (warnings?.address1) {
          setFocus('address1');
      } else if (warnings?.address2) {
          setFocus('address2');
      } else if (warnings?.city) {
          setFocus('city');
      } else if (warnings?.postalCode) {
          setFocus('postalCode');
      } else if (warnings?.provinceOrState) {
          setFocus('provinceOrState');
      }
    }, [warnings?.address1, warnings?.address2, warnings?.city, warnings?.postalCode, warnings?.provinceOrState, hasWarning]);
    
    return (
        <form id="step-one-form-billing" className={defaultFormStyles} onSubmit={handleSubmit(onSubmit)}>
            {/* Address1 */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 Form__Element FormTextbox ${errors.address1 ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="address1">{t("FormFields.AddressOne.Label")} *</label>
                    <input
                        {...register("address1",
                            {
                                required: t("FormFields.AddressOne.Required"),
                                pattern: {
                                    value: ADDRESS_CHARACTER_LIMIT_REGEX,
                                    message: address1Warning ? '' : t("FormFields.AddressTwo.Required.CharacterLimit")
                                }
                            })}
                        type="text"
                        className={`input flex-[50%]`}
                    />
                </div>
                
                <div className="flex flex-col gap-3">
                    {address1Warning ? '' : renderErrors(errors?.address1)}
                    {address1Warning && <span className="text-red-600">{t("FormFields.AddressTwo.Required.CharacterLimit")}</span>}
                    {renderWarnings(warnings?.address1)}
                </div>
            </div>

            {/* Address2 */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 Form__Element FormTextbox ${errors.address2 ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="address2">{t("FormFields.AddressTwo.Label")}</label>
                    <input
                        {...register("address2",
                            {
                                pattern: {
                                    value: ADDRESS_CHARACTER_LIMIT_REGEX,
                                    message: address2Warning ? '' : t("FormFields.AddressTwo.Required.CharacterLimit")
                                }
                            })}
                        type="text"
                        className={`input flex-[50%]`}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {address2Warning ? '' : renderErrors(errors?.address2)}
                    {address2Warning && <span className="text-red-600">{t("FormFields.AddressTwo.Required.CharacterLimit")}</span>}
                    {renderWarnings(warnings?.address2)}
                </div>
            </div>

            {/* City with required ex*/}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.city ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="city">{t("FormFields.City.Label")} *</label>
                    <input
                        {...register("city", {
                            required: t("FormFields.City.Required")
                        })}
                        type="text" 
                        className={`input flex-[50%]`}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.city)}
                    {renderWarnings(warnings?.city)}
                </div>
            </div>

            {/* Postal Code */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.postalCode ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="postalCode">{t("FormFields.PostalCode.Label")} *</label>
                    <input 
                        {...register("postalCode",
                        {
                            required: t("FormFields.PostalCode.Required"),
                            pattern: {
                                value: getPostalCodeRegex(watchedFields.countryCode ?? "US"),
                                message: t("FormFields.PostalCode.Pattern")
                            }
                        })}
                        type="text" 
                        className={`input flex-[50%]`}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.postalCode)}
                    {renderWarnings(warnings?.postalCode)}
                </div>
            </div>

            {/* Country */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.countryCode ? 'ValidationFail' : ''}`}>

                    <label className={"font-bold text-black flex-1"} htmlFor="countryCode">{t("FormFields.Country.Label")} *</label>

                    <select {...register("countryCode",
                        {
                            required: t("FormFields.Country.Required")
                        })}
                    >
                        <option value="" disabled>Select a country</option>
                        {localizations?.countries.map((value) => {
                            return(
                                <option key={value.name} value={value.name}>{value.code}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.countryCode)}
                </div>
            </div>

            {/* Province or State */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.provinceOrState ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="provinceOrState">{t("FormFields.StateOrProvince.Label")} *</label>
                    <select {...register("provinceOrState",
                        {
                            required: t("FormFields.StateOrProvince.Required")
                        })}
                        defaultValue=""
                    >
                        <option value="" disabled>Select a state</option>
                        {localizations?.statesAndProvinces.filter(x => x.country.name === watchedFields.countryCode)?.map((value, idx)=>{
                            return (
                                <option key={value.name} value={value.name}>{value.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.provinceOrState)}
                    {renderWarnings(warnings?.provinceOrState)}
                </div>
            </div>

            {/* Email Address */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.email ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="email">{t("FormFields.Email.Label")} *</label>
                    <input 
                        {...register("email",
                        {
                            required: t("FormFields.Email.Required"),
                            pattern: {
                                value: EMAIL_REGEX,
                                message: t("FormFields.Email.Pattern")
                            }
                        })}
                        type="text" 
                        className={`input flex-[50%]`}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.email)}
                </div>
          </div>

        <div className="flex flex-col">
            <div className={`flex flex-col gap-3 ${errors.phone ? 'ValidationFail' : ''}`}>
            <label className={"font-bold text-black flex-1"} htmlFor="phone">{t("FormFields.Phone.Label")} *</label>
            <Controller
                name="phone"
                control={control}
                rules={{
                required: t("FormFields.Phone.Required"),
                pattern: {
                    value: /^\d{3}-\d{3}-\d{4}$/,
                    message: t("FormFields.Phone.Pattern")
                },
                }}
                render={({ field }) => (
                <input
                    {...field}
                    type="text"
                    className={`flex-[50%]`}
                    onChange={(e) => {
                        const maskedValue = phoneNumberMask(e.target.value);
                        field.onChange(maskedValue);
                    }}
                />
                )}
            />
            </div>
            <div className="flex flex-col gap-3">
                {renderErrors(errors?.phone)}
            </div>
        </div>
    </form>
  )
}

// Set default values to some of the properties.
StepOneBillingForm.defaultProps = {
  className: "flex flex-col",
}

export default StepOneBillingForm
