import BlockComponentSelector from '@/epi/block-component-selector'
import { ResourceDownloadFormPageProps } from './types/resource-download-form-page-types'
import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedRtf from '@/components/shared/rtf/shared-rtf'

const ResourceDownloadFormPage: React.FC<
    ResourceDownloadFormPageProps
    > = ({ model }) => {
    return (
        <>
            <Breadcrumbs />
            <div className="container flex flex-col space-y-12 md:space-y-16">
                {model.hero && <BlockComponentSelector {...model.hero} />}
                <div className="flex flex-col lg:flex-row items-start justify-center space-y-12">
                            {model.resourceDownloadFormPageBodyText && (
                        <div className='w-full h-auto lg:w-3/5'>
                            <SharedRtf html={model.resourceDownloadFormPageBodyText} />
                                </div>
                            )}
                            {model.resourceDownloadFormScript && (
                        <div className='w-full h-auto lg:w-2/5'
                            dangerouslySetInnerHTML={{ __html: model.resourceDownloadFormScript }}>
                        </div>
                    )}
                </div>
                <div className="flex flex-col space-y-12 md:space-y-16">
                    {model.resourceDownloadPageContent?.map((item, i) => (
                        <BlockComponentSelector {...item} key={`pageContent-${i}`} />
                    ))}
                </div>
            </div>
        </>
    )
}
export default ResourceDownloadFormPage